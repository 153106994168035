<template>
    <div id="content">
        <!-- 头部 -->
        <header>
            <img @click="goHome" src="../../assets/img/go.png" alt="">
            <span style="padding-left: 33%" class="fs">公司新闻</span>
        </header>
        <!-- 第二部分 -->
        <div class="photo_img">
            <p class="fb">公司新闻</p>
        </div>
        <!-- 第三部分 -->
        <div class="new_details">
            <div class="new_content" v-for="(item,index) in newList" :key="index">
                <p class="new_text fs">
                    {{item.title}}
                </p>
                <p class="new_time fs">{{item.createTime}}</p>
                <p class="line"></p>
                <div v-for="(each,num) in item.articleSpecificList" :key="num">
                    <p class="newTitle fs">
                        {{each.content}}
                    </p>
                    <div style="width:100%;text-align: center">
                        <img v-if="each.imgUrl == null? '':item.imgUrl" :src="each.imgUrl" alt="">
                    </div>

                </div>
            </div>
        </div>
        <!-- 尾部 -->
        <footerEn></footerEn>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    data() {
        return {
            id: '',
            newList:[]
        };
    },
    mounted() {
        if (!/Android|iPhone|iPod/i.test(navigator.userAgent)) {
            this.$router.push('/newDetailsOne')
        } else {
            window.addEventListener('resize', this.remScale());
        }
        this.id = this.$route.query.id;
        this.getNewList();
    },
    methods: {
        goHome(){
            this.$router.push('/companyNews');
        },
        remScale() {
            var winW = document.documentElement.clientWidth;
            winW = winW > 750 ? 750 : winW;
            document.documentElement.style.fontSize = winW / 750 * 100 + 'px'
        },
        getNewList() {
            let _this = this;
            $.ajax({
                type: 'post',
                url: _this.requestUrl + 'getArticle',
                data: {
                    id: _this.id
                },
                dataType: 'json',
                success: function (res) {
                    if (res.code == 1000) {
                        _this.newList = res.data
                    }
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
    @import "../../assets/css/newDetailsOne_en.scss";
</style>
